export const categories = [
  { cats: "Health & Beauty" },
  { cats: "Phones" },
  { cats: "Laptops" },
  { cats: "Real Estate" },
  { cats: "Pharmaceutical" },
  { cats: "Drinks & Beverages" },
  { cats: "FoodStuffs" },
  { cats: "Fashion" },
  { cats: "Furniture" },
  { cats: "Automobile" },
  { cats: "Home Appliances" },
  { cats: "Baby Products" },
];
